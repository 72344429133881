//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { imgChange } from "@/commons";
import teamOptions from "@/mixins/teamOptions";
export default {
  mixins: [teamOptions],
  components: {
    Header: () => import("@/components/Header"),
    Tab: () => import("@/components/TabUtil"),
    CContent: () => import("@/components/CContent"),
    CPages: () => import("@/components/CPages"),
    MsgMass: () => import("./MsgMass"),
    MsgDetail: () => import("./msg-detail"),
  },
  data() {
    return {
      functionName: "消息管理",
      selMsg: null, //选择查看的消息
      msgData: [],
      tabOptions: ["未读消息", "已读消息", "我发送的消息"],
      loading: false,
      pageData: {
        pageIndex: 1,
        pageSize: 10,
        totalNum: 0,
      },
      activeItem: "未读消息",
    };
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
  },
  methods: {
    imgChange,
    handleRead(val) {
      if (this.activeItem == "未读消息") {
        this.$http.post("/Information/ReadInformation.ashx", {
          teamId: this.teamValue,
          Id: val.Id,
        });
      }
      this.selMsg = val;
      this.$modal.show("msgDetail");
    },
    /**
     * 全部标记已读
     */
    handleAllRead() {
      this.$confirm("此操作将全部消息标记为已读, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$http
            .post("/Information/AllReadInformation.ashx", {
              teamId: this.teamValue,
            })
            .then((resp) => {
              if (resp.res == 0) {
                this.$message({
                  type: "success",
                  message: "标记成功!",
                });
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消标记",
          });
        });
    },
    /**
     * 搜索
     */
    handleGetData() {
      this.pageData.pageIndex = 1;
      this.getMsg();
    },
    /**
     * 获取消息
     */
    getMsg() {
      if (this.activeItem == "未读消息") {
        this.myMsg(2);
      } else if (this.activeItem == "已读消息") {
        this.myMsg(1);
      } else {
        this.mySendMsg();
      }
    },
    /**
     * 我发送的消息
     */
    mySendMsg() {
      this.loading = true;
      const data = {
        teamId: this.teamValue,
        pageIndex: this.pageData.pageIndex,
        pageSize: this.pageData.pageSize,
      };
      this.$http
        .post("/Information/GetInformations.ashx", data)
        .then((resp) => {
          if (resp.res == 0) {
            this.msgData = resp.data.Data;
            this.pageData.totalNum = resp.data.TotalCount;
          }
        })
        .finally(() => (this.loading = false));
    },
    /**
     * 我的消息
     */
    myMsg(type) {
      this.loading = true;
      const data = {
        teamId: this.teamValue,
        type: type,
        pageIndex: this.pageData.pageIndex,
        pageSize: this.pageData.pageSize,
      };
      this.$http
        .post("/Information/GetUserReadInformations.ashx", data)
        .then((resp) => {
          if (resp.res == 0) {
            this.msgData = resp.data.Data;
            this.pageData.totalNum = resp.data.TotalCount;
          }
        })
        .finally(() => (this.loading = false));
    },
    /**
     * 群发消息
     */
    handleMass() {
      this.$modal.show("MsgMass");
    },
    getTab(item) {
      this.activeItem = item.join();
      if (this.teamValue) {
        this.handleGetData();
      } else {
        this.$message({
          showClose: true,
          message: "请先选择团队！",
          type: "warning",
          duration: 2000,
        });
        return;
      }
    },
    /**
     * 分页
     */
    pageChange(val) {
      this.pageData = val;
      this.getMsg();
    },
  },
};
